import * as React from "react";
import { navigate } from 'gatsby';
import { useAuth0 } from '../../hooks/use-auth0';

const LogoutPage = () => {
    const { authProvider } = useAuth0();
    React.useEffect(() => {
        async function logout() {
            await authProvider.logout();
        }
        logout().then(() => {
            navigate('../login');
        });
    }, [authProvider]);
    
    return (<>
        <h1>Logged Out</h1>
        <a href="../login">Log in</a>
    </>)
};

export default LogoutPage;

